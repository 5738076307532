.report-category-hero
    width: 100%
    max-width: 1400px
    position: relative
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-between
    box-sizing: border-box
    gap: 32px
    font-family: 'Poppins'

.categories-wrapper 
    width: 100%
    display: grid
    padding: 8px 15px
    grid-template-columns: repeat(4, minmax(200px, 1fr))
    gap: 32px
    align-items: stretch
    overflow: hidden
    

    @media screen and (max-width: 1280px) 
        grid-template-columns: repeat(2, minmax(300px, 1fr))

        & > *:nth-child(4):nth-last-child(1) 
            grid-column: span 3
        

    @media screen and (max-width: 1200px) 
        grid-template-columns: repeat(3, minmax(200px, 1fr))

        & > *:nth-child(4):nth-last-child(1) 
            grid-column: span 3
    

    @media screen and (max-width: 1024px) 
        grid-template-columns: repeat(2, minmax(250px, 1fr))

        & > *:nth-child(4):nth-last-child(1) 
            grid-column: span 1
    

    @media screen and (max-width: 992px) 
        grid-template-columns: repeat(1, 1fr)
        
        & > *:nth-child(4):nth-last-child(1) 
            grid-column: span 1
        
    
    @media screen and (max-width: 768px) 
        grid-template-columns: 1fr
        gap: 20px

        & > *:nth-child(4):nth-last-child(1) 
            grid-column: span 1


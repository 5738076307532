.cancel-subscription-confirmation-card
    padding: 20px 64px 64px 64px
    display: flex
    width: 100%
    max-width: 1200px
    height: auto
    aspect-ratio: 2.5 / 1
    margin: 0 auto
    align-items: center
    justify-content: space-between
    align-content: flex-start
    flex-direction: column
    box-sizing: border-box
    background: linear-gradient(98.6deg, #014365, #012437)
    background-size: cover
    background-repeat: no-repeat
    background-position: top
    text-align: center
    font-size: 24px
    border-radius: 32px
    overflow: hidden

    @media (max-width: 1024px)
        padding: 40px 30px
        font-size: 22px
        flex-direction: column
        aspect-ratio: 1.5 / 1
        align-items: center

    @media (max-width: 820px)
        padding: 20px 10px
        font-size: 18px
        border-radius: 0
        aspect-ratio: auto

.close-button
    position: relative
    width: 100%
    margin: 0 !important
    background: none
    border: none
    cursor: pointer
    display: flex
    justify-content: flex-end
    color: #fff !important
    
    &:hover
        color: #FF5522

.close-icon
    font-size: 30px
    color: #fff !important
    cursor: pointer

    &:hover
        color: #FF5522 !important

.main-content
    width: 100%
    position: relative
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    gap: 16px
    text-align: left
    font-size: 32px
    color: #fff
    font-family: 'Poppins'

    @media (max-width: 820px)
        display: flex
        max-width: 100%
        align-items: center
        text-align: center
        justify-content: center
        flex-direction: column

.left-wrapper
    flex: 0.2
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    height: auto

    @media (max-width: 820px)
        display: flex
        max-width: 100%
        align-items: center
        text-align: center

.right-wrapper
    flex: 1
    max-width: 90%
    height: auto
    position: relative
    display: flex
    gap: 32px
    flex-direction: column
    justify-content: space-between
    align-items: flex-start

    @media (max-width: 1024px)
        display: flex
        max-width: 100%
        align-items: center
        text-align: center

    @media (max-width: 820px)
        max-width: 100%
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        text-align: center

.sad-bot-icon
    width: 100%
    position: relative
    height: 163px
    object-fit: cover

.divider
    position: relative
    width: 100%
    height: 0.5px
    border-top: 0.5px solid #e3e3e3
    box-sizing: border-box
    margin: 16px 0

.title
    font-size: 32px
    line-height: normal
    width: 100%
    margin: 0
    color: #fff
    text-align: left
    font-weight: 500

    @media (max-width: 1024px)
        font-size: 30px

    @media (max-width: 768px)
        text-align: center
        font-size: 24px

.description
    width: 100%
    font-size: 20px
    text-align: left
    display: inline-block
    font-weight: 400

    &.strong
        font-weight: 700

    @media (max-width: 820px)
        text-align: center
        font-size: 18px

.button-group
    width: 100%
    position: relative
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: flex-start
    gap: 16px

    @media (max-width: 820px)
        display: flex
        max-width: 100%
        align-items: center
        text-align: center
        justify-content: center
        flex-direction: column

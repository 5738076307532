.profile-body-section
    position: relative
    display: flex
    flex-direction: column
    width: 100%
    max-width: 1440px
    margin: 0 auto
    height: auto
    overflow: hidden
    align-content: flex-start
    justify-content: space-between
    align-items: flex-start
    box-sizing: border-box
    padding: 0px 20px 60px 20px

    @media (max-width: 1024px)
        height: auto
        flex-direction: column
        align-items: center
        padding: 40px 60px

    @media (max-width: 768px)
        flex-direction: column
        padding: 40px 20px

.overlay
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, 0.5)
    z-index: 1000
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    gap: 16px
    padding: 16px
    box-sizing: border-box
    letter-spacing: 1px

    @media (max-width: 768px)
        font-size: 20px
        padding: 8px

.no-profile-container
    display: flex
    flex-direction: column
    gap: 16px
    align-items: center
    justify-content: center
    width: 100%
    height: 100%
    padding: 20px
    box-sizing: border-box
    text-align: center
    font-size: 20px
    font-family: 'Poppins'
    color: #1e1e1e

    @media (max-width: 768px)
        font-size: 16px
        padding: 8px

.comingSoonSection
    padding: 60px 0
    min-height: 80vh
    display: flex

    @media (max-width: 768px)
        padding: 40px 0

.container
    max-width: 1400px  
    margin: 0 auto
    padding: 0 20px
    display: flex
    justify-content: space-between
    align-items: center

    @media (max-width: 1024px)
        padding: 0 10px

.content
    flex: 1
    max-width: 60%  
    margin-right: 120px  

    @media (max-width: 1024px)
        max-width: 100%
        margin-right: 0px

    @media (max-width: 768px)
        margin-bottom: 20px

    @media (max-width: 480px)
        margin-bottom: 20px
        padding: 0 10px

.headline
    display: flex
    flex-direction: column
    align-items: flex-start
    margin-bottom: 20px
    text-align: left

    @media (max-width: 768px)
        align-items: center
        text-align: center

.workspace
    color: #FFFFFF
    font-family: 'Poppins', sans-serif
    font-size: 20px
    font-weight: 300
    line-height: 1.2
    margin-bottom: 0px

.comingSoon
    color: #FF5522
    font-family: 'Poppins', sans-serif
    font-size: 56px
    font-weight: 700
    line-height: 1.2 

    @media (max-width: 768px)
        font-size: 48px
        line-height: 1.2

.description
    font-family: 'Open Sans', sans-serif
    font-size: 22px
    font-weight: 300
    color: #FFFFFF
    margin-bottom: 40px
    text-align: left

.loader-container
    display: flex
    width: 100%
    justify-content: center
    align-items: center
    margin-top: 20px

.form
    display: flex
    gap: 10px

    @media (max-width: 768px)
        flex-direction: column
        align-items: center

.emailInput
    flex-grow: 1
    padding: 8px 16px  
    font-size: 15px
    border: none
    border-radius: 4px
    height: auto
    min-width: 60%

.imageContainer
    flex: 1
    display: flex
    justify-content: flex-end
    align-items: center
    margin-left: 40px  

.comingSoonImage
    max-width: 100%
    height: auto
    @media (max-width: 768px)
        height: 36px
        min-width: 160px

    @media (max-width: 480px)
        height: 32px
        min-width: 140px

@media (max-width: 1024px)
    .container
        flex-direction: column
        align-items: center

    .content, .imageContainer
        max-width: 100%
        text-align: center

    .headline
        align-items: left

    .form
        justify-content: left

    .imageContainer
        margin-top: 40px

@media (max-width: 768px)
    .workspace
        font-size: 15px

    .comingSoon
        font-size: 36px
        line-height: 108px

    .description
        font-size: 24px
        line-height: 32px

    .form
        flex-direction: column

    .emailInput
        width: 100%

@media (max-width: 480px)
    .workspace
        font-size: 15px

    .comingSoon
        font-size: 48px
        line-height: 72px

    .description
        font-size: 18px
        line-height: 24px


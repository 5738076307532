.report-category-card
    flex: 1
    position: relative
    min-height: 180px
    flex-shrink: 0
    box-shadow: 0px 2px 9.1px
    background-color: #fff
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    overflow: hidden
    padding: 8px 16px
    border-radius: 16px
    box-sizing: border-box
    gap: 32px
    cursor: pointer
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease, margin 0.3s ease

    &:hover
        transform: scale(1.02)

    @media (max-width: 768px)
        min-height: 150px
        padding: 8px
        gap: 24px

    @media (max-width: 480px)
        min-height: 120px
        padding: 4px
        gap: 16px

.category-icon
    width: 48px
    border-radius: 50%
    height: 48px
    overflow: hidden
    flex-shrink: 0
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    padding: 3px
    box-sizing: border-box
    margin-top: 16px

    @media (max-width: 768px)
        width: 40px
        height: 40px

    @media (max-width: 480px)
        width: 32px
        height: 32px

.icon
    width: 18px
    height: 18px
    position: relative

.category-title
    width: 100%
    position: relative
    font-size: 24px
    line-height: 32px
    font-weight: 600
    font-family: 'Open Sans'
    color: #0a0a0a
    text-align: center
    display: inline-block

    @media (max-width: 768px)
        font-size: 20px
        line-height: 28px

    @media (max-width: 480px)
        font-size: 18px
        line-height: 24px
.maintenance
    position: sticky
    width: 100%
    top: 100%
    right: 0
    left: 0
    z-index: 999
    display: flex
    padding: 10px 80px
    flex-direction: column
    align-items: center
    justify-content: space-between
    gap: 10px
    align-content: center
    border-bottom: 1px solid rgba(112, 112, 112, 0.2)
    font-family: 'Poppins'
    background: linear-gradient(180.5deg, #e9f7ff, #f7fcff)

    @media (max-width: 1024px)
        padding: 10px 40px
        gap: 20px

    @media (max-width: 768px)
        padding: 10px 20px
        gap: 10px
        flex-direction: column
        align-items: center
        justify-content: center
        align-content: center
        text-align: center

.maintenance-title
    font-size: 24px
    font-weight: 600
    color: #282828
    text-align: center

    @media (max-width: 768px)
        font-size: 20px

.maintenance-text
    font-size: 16px
    font-weight: 400
    color: #707070
    text-align: center

    @media (max-width: 768px)
        font-size: 14px
    
.add-interested-industries-card
    padding: 20px
    display: flex
    position: relative
    width: 100%
    max-width: 500px
    height: auto
    max-height: 80vh
    margin: 0 auto
    align-items: center
    justify-content: space-between
    align-content: flex-start
    flex-direction: column
    box-sizing: border-box
    background: rgba(255, 255, 255, 1)
    background-size: cover
    background-repeat: no-repeat
    background-position: top
    text-align: center
    font-size: 24px
    border-radius: 32px
    overflow: hidden
    gap: 24px

    @media (max-width: 820px)
        width: 100%
        max-width: 90vw
        max-height: 90vh
        height: auto
        padding: 20px
        font-size: 18px
        border-radius: 0
        aspect-ratio: auto
        align-content: flex-start
        justify-content: flex-start
        gap: 8px

.card-header
    width: 100%
    display: inline-block
    min-height: 50px
    flex-direction: column
    align-items: flex-start
    justify-content: space-between
    gap: 16px

.button-container
    width: 100%
    display: flex
    flex-direction: row
    align-items: flex-end
    justify-content: flex-end

.close-button
    position: relative
    width: auto
    margin: 0 !important
    background: none
    border: none
    cursor: pointer
    display: flex
    align-items: flex-end
    justify-content: flex-end
    color: rgba(33, 111, 151, 1) !important
    
    &:hover
        color: #FF5522 !important

.close-icon
    font-size: 30px
    color: rgba(33, 111, 151, 1) !important
    cursor: pointer

    &:hover
        color: #FF5522 !important

.card-header-text
    width: 100%
    font-size: 16px
    font-weight: 400
    font-family: 'Poppins'
    text-align: left

    @media (max-width: 768px)
        font-size: 20px

.search-bar
    width: 100%
    min-height: 40px
    display: flex
    flex-direction: row
    align-items: flex-start
    justify-content: flex-start
    gap: 8px

.card-content
    width: 100%
    height: 80%
    padding: 10px 0px
    display: inline-block
    flex-direction: column
    align-items: center
    justify-content: center
    overflow-y: auto
    overflow-x: hidden
    gap: 16px

.industries-list
    display: flex
    width: 100%
    height: 100%
    gap: 8px
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    text-align: left
    font-size: 14px
    color: #1666da
    font-family: 'Poppins'
    padding: 2px 0px
    margin: 0

    @media (max-width: 768px)
        font-size: 12px
        gap: 20px

.industry-item
    position: relative
    border-radius: 4px
    background-color: #e1ecfc
    width: auto
    min-height: 40px
    font-weight: 600
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    padding: 4px 8px
    gap: 8px
    box-sizing: border-box
    text-align: left
    font-size: 14px
    color: #1666da
    font-family: 'Poppins'
    cursor: pointer

    @media (max-width: 768px)
        font-size: 12px
        padding: 2px

    &:hover
        transform: scale(1.001)
        transition: all 0.2s
        background-color: #d6e7fc

.selected
    background-color: #1666da
    color: #fff

    &:hover
        transform: scale(1.001)
        transition: all 0.2s
        background-color: #1666da

.card-footer
    width: 100%
    display: flex
    min-height: 50px
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 16px
    padding: 0px 0px 20px 0px
.interested-industries
    display: flex
    flex-direction: column
    gap: 16px
    width: 100%
    height: auto
    margin: 0
    position: relative
    align-items: flex-start
    justify-content: flex-start
    text-align: left
    flex: 1

    @media (max-width: 1024px)
        display: flex
        max-width: 100%
        align-items: flex-start
        text-align: left

    @media (max-width: 768px)
        max-width: 100%
        width: 100%
        align-items: flex-start
        text-align: start

.title, .item
    width: 100%
    position: relative
    font-size: 20px
    font-weight: 600
    margin: 0
    font-family: 'Poppins'
    text-align: left
    display: inline-block

    @media (max-width: 768px)
        font-size: 16px

.header
    width: 100%
    position: relative
    font-size: 24px
    font-weight: 600
    margin: 0
    font-family: 'Poppins'
    text-align: left
    display: flex
    align-items: center
    justify-content: space-between

    @media (max-width: 768px)
        font-size: 20px

.title
    color: #707070
    font-size: 18px
    font-family: 'Open Sans'
    font-weight: 300

.list
    display: flex
    width: 100%
    flex-wrap: wrap
    gap: 40px
    flex-direction: row
    align-items: flex-start
    justify-content: flex-start
    text-align: left
    font-size: 14px
    color: #1666da
    font-family: 'Poppins'
    padding: 0
    margin: 0

    @media (max-width: 768px)
        font-size: 12px
        gap: 20px

.item
    position: relative
    border-radius: 4px
    background-color: #e1ecfc
    width: auto
    min-height: 40px
    font-weight: 600
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    padding: 4px 8px
    gap: 8px
    box-sizing: border-box
    text-align: left
    font-size: 14px
    color: #1666da
    font-family: 'Poppins'
    cursor: pointer

    @media (max-width: 768px)
        font-size: 12px
        padding: 2px

    &:hover
        transform: scale(1.05)
        transition: all 0.2s
        background-color: #d6e7fc


.close-icon
    font-size: 18px

.close-button
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    min-height: 40px
    
    &:hover
        color: #FF5522
        transition: color 0.2s
@import "../global/variables"
    
.trusted-logos-section
  position: relative
  background: transparent
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  gap: $za-gap-trusted-feature
  padding: 100px 0 100px 0
  box-sizing: border-box
  overflow: hidden

  @media (max-width: 768px)
    padding: $za-spacing-section-padding

.section-title
  width: 100%
  text-align: center
  justify-content: center
  position: relative
  font-size: 20px
  color: #707070
  letter-spacing: 0.1em
  margin-bottom: 40px
  font-family: $za-font-family-open-sans
  text-transform: uppercase

  @media (max-width: 768px)
    font-size: $za-font-size-section-heading-small
    text-align: center

.logos-container
  display: flex
  align-items: center
  justify-content: center
  gap: $za-gap-section-default
  flex-wrap: nowrap
  white-space: nowrap
  overflow: hidden
  animation: scrollLogos var(--scroll-duration) linear infinite
  animation-delay: 1500ms // Start after 1500ms delay.
  animation-duration: 20000ms

.logos-container.start-scrolling
  animation: scrollLogos var(--scroll-duration) linear infinite
  animation-delay: 1500ms
  animation-duration: 20000ms

@keyframes scrollLogos
  0%
    transform: translateX(25%)
  
  10%
    transform: translateX(25%)
  
  55%
    transform: translateX(-25%)
    
  65%
    transform: translateX(-25%)
  
  100%
    transform: translateX(25%)

@media (min-width: 1800px) and (max-width: 2000px)
  @keyframes scrollLogos
    0%
      transform: translateX(20%)
    10%
      transform: translateX(20%)
    55%
      transform: translateX(-20%)
    65%
      transform: translateX(-20%)
    100%
      transform: translateX(20%)

@media (min-width: 1024px) and (max-width: 1200px)
  @keyframes scrollLogos
    0%
      transform: translateX(30%)
    
    10%
      transform: translateX(30%)
    
    55%
      transform: translateX(-30%)
      
    65%
      transform: translateX(-30%)
    
    100%
      transform: translateX(30%)

@media (min-width: 2000px)
  .logos-container
    display: flex
    justify-content: center
    animation: none // Disable scrolling animation for large screens.

@media (max-width: 768px)
  @keyframes scrollLogos
    0%
      transform: translateX(35%)
    10%
      transform: translateX(35%)
    55%
      transform: translateX(-35%)
    65%
      transform: translateX(-35%)
    100%
      transform: translateX(35%)

@media (max-width: 480px)
  @keyframes scrollLogos
    0%
      transform: translateX(40%)
    10%
      transform: translateX(40%)
    55%
      transform: translateX(-40%)
    65%
      transform: translateX(-40%)
    100%
      transform: translateX(40%)

.logo-item
  width: 300px
  height: 132px
  flex-shrink: 0
  object-fit: contain
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  cursor: pointer
  display: inline-block

  &:hover
    transform: scale(1.05)

.logo-item.small
  width: 300px
  height: 92px

.logo-item.llclogo
  width: 194px
  height: 194px

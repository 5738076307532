.header-navigation
    width: 100%
    display: flex
    position: relative
    flex-direction: column
    align-items: center
    justify-content: center

.header-navigation-container
    width: 100%
    padding: 50px 80px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-between
    box-sizing: border-box
    position: relative
    color: #fff
    border-bottom: 1px solid transparent
    border-image: linear-gradient(90deg, rgba(92, 150, 181, 0.1) 0%, #6FA8C5 50%, rgba(92, 150, 181, 0.1) 100%) 1

    @media (max-width: 1024px)
        padding: 20px

.desktop-header
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-between
    width: 100%
    position: relative
    max-width: 1400px

    @media (max-width: 1024px)
        display: none

.logo-wrapper
    display: flex
    width: 100%
    align-items: center
    justify-content: flex-start
    align-content: flex-start

.logo
    height: 50px

.nav-and-actions
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    max-width: 1400px
    margin-top: 10px

.nav-links
    display: flex
    gap: 30px
    flex-grow: 1
    margin-left: auto
    color: #fff

    @media (max-width: 1024px)
        display: none

.nav-link
    display: flex
    direction: row
    justify-content: flex-start
    align-items: center
    gap: 8px
    flex-shrink: 0
    font-family: 'Open Sans'
    text-decoration: none
    font-size: 20px
    font-weight: 400

    &:hover
        color: #ff5522

    @media (max-width: 1200px)
        font-size: 16px

.coming-soon
    display: flex
    border-radius: 8px
    justify-content: center
    align-items: center
    padding: 8px 4px
    background: #FF5522
    font-size: 12px
    color: #fff
    
    &:hover
        color: #fff

.action-buttons
    display: flex
    height: 48px
    gap: 15px

.user-action-menu
    position: relative
    display: inline-block

    &:hover .quick-actions-wrapper
        visibility: visible
        opacity: 1
        transform: translateY(0)

    p
        font-family: 'Poppins'
        font-size: 20px
        font-weight: 400
        text-align: right
        color: #fff
        margin: 0

.user-avatar-wrapper
    display: flex
    flex-direction: row
    gap: 10px
    align-items: center
    justify-content: center
    align-content: center
    margin-right: 10px

.avatar
    position: relative
    width: 48px
    height: 48px
    border-radius: 50%
    border: solid #fff 1px
    overflow: hidden
    transition: transform 0.3s ease-in-out
    cursor: pointer

    &:hover
        transform: scale(1.1)

.avatar-icon
    width: 100%
    height: 100%

.quick-actions-wrapper
    position: absolute
    display: flex
    flex-direction: column
    top: calc( 100% + 8px )
    left: auto
    right: 0
    background: #fff
    align-items: flex-start
    justify-content: flex-start
    min-width: 250px
    max-width: 300px
    width: fit-content
    z-index: 1000
    border-radius: 16px
    flex-shrink: 0
    box-shadow: 2px 4px 27.1px 4px rgba(179, 206, 220, 0.44)
    padding: 25px 30px
    gap: 24px
    visibility: hidden
    opacity: 0
    transform: translateY(-10px)
    transition: opacity 0.2s ease, transform 0.5s ease

    @media (max-width: 768px)
        left: 0
        right: 0
        min-width: 100%
        max-width: 100%
        padding: 20px
        box-shadow: none
        border-radius: 0

    @media (max-width: 768px)
        left: 0
        right: 0
        min-width: 100%
        max-width: 100%
        padding: 20px
        box-shadow: none
        border-radius: 0

.quick-action-item, .quick-action-footer
    position: relative
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    text-align: start
    font-size: 20px
    color: #005581
    font-family: 'Open Sans'

    &:hover
        color: #ff5522

    .item-text
        text-align: start
        width: 100%
        color: #005581
        font-weight: 400
        font-family: 'Open Sans'
        cursor: pointer

        &:hover
            color: #ff5522

.quick-action-footer
    gap: 12px

    .footer-text
        flex: 1
        width: 100%
        font-size: 14px
        position: relative
        display: inline-block
        font-family: 'Poppins'
        color: #f52
        text-align: left
        font-weight: 400

.item-divider
    width: 100%
    margin: 0!important
    border-top: 1px solid rgba(112, 112, 112, 0.2)
    box-sizing: border-box
    height: 1px


.mobile-header
    width: 100%
    display: none !important
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: space-between

    @media (max-width: 1024px)
        display: flex !important

.top-wrapper
    display: flex
    width: 100%
    padding: 30px 10px
    align-items: center
    justify-content: space-between
    align-content: center
    border-bottom: 1px solid transparent

    > *:first-child
        margin-left: 0
        max-width: 138px
        flex: 1
    
    > *:last-child
        margin-right: 0

.menu-toggle
    display: flex
    width: 24px
    height: 24px
    background: none
    border: none
    color: #fff
    font-size: 30px
    cursor: pointer

.bottom-wrapper
    display: flex
    flex-direction: column
    width: 100%
    padding: 40px 10px
    gap: 32px
    align-items: flex-start
    justify-content: flex-start
    align-content: flex-start


.mobile-nav-links
    display: flex
    flex-direction: column
    font-family: 'Open Sans'
    color: #fff
    text-decoration: none
    gap: 16px
    justify-content: space-between
    align-content: flex-start
    justify-content: flex-start
    align-items: center
    font-weight: 400

    &:hover
        color: #ff5522

.mobile-link
    display: flex
    direction: row
    justify-content: flex-start
    align-content: center
    align-items: center
    width: 100%
    font-family: 'Open Sans'
    font-size: 16px
    font-weight: 400
    text-align: start
    text-decoration: none
    gap: 8px
    padding: 10px 0

    &:hover
        color: #f52

.active
    display: flex

.mobile-action-buttons
    display: flex
    flex-direction: column
    width: 100%
    gap: 15px

.payment-status
    position: sticky
    width: 100%
    top:  100%
    right: 0
    left: 0
    z-index: 1000
    margin-top: 0
    padding: 10px 80px
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    align-content: center
    border-bottom: 1px solid rgba(112, 112, 112, 0.2)
    font-family: 'Poppins'
    background: linear-gradient(180.5deg, #e9f7ff, #f7fcff)

    @media (max-width: 1024px)
        padding: 10px 20px

    @media (max-width: 768px)
        padding: 10px 20px
        flex-direction: column
        gap: 10px

.payment-status-text, .payment-link
    font-size: 16px
    font-weight: 400
    color: #005581
    font-family: 'Open Sans'
    text-align: start
    margin: 0

.payment-link
    color: #f52
    text-decoration: underline
    cursor: pointer
    
    &:hover
        color: #ff5522


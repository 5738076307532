
@import "../global/variables"

.modal-overlay
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgba(0, 0, 0, 0.5)
  display: flex
  justify-content: center
  align-items: center
  z-index: 1000

.modal-header
  display: flex
  justify-content: space-between
  align-items: center
  padding: 10px

.modal-content
  padding: 20px
  background: #00000000
  border-radius: 10px
  max-width: 600px
  max-height: 90vh
  overflow-y: auto

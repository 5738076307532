.report-payment-form
    width: auto
    position: relative
    display: flex
    flex-direction: column
    gap: 20px
    border-radius: 16px
    align-items: flex-start
    box-shadow: 0px 4px 18.8px rgba(1, 23, 34, 0.1)
    background-color: #fff
    justify-content: space-between
    padding: 40px
    box-sizing: border-box
    max-height: 80vh
    overflow: hidden

    @media (max-width: 1280px)
        padding: 25px
        max-width: 90%

    @media (max-width: 768px)
        padding: 20px
        gap: 15px
        max-width: 95%

    @media (max-width: 480px)
        padding: 15px
        gap: 10px
        border-radius: 8px
        max-width: 100%

.header-container
    width: auto
    position: relative
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    gap: 12px

.logo-container
    display: flex
    width: 100%
    gap: 10px
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    align-content: flex-start

.zerone-logo
    height: 25px

    @media (max-width: 480px)
        height: 20px

.form-title
    font-size: 24px
    font-weight: 300 
    font-family: 'Poppins'
    text-align: left
    margin: 0
    color: #005581

.loader
    width: 100%
    height: auto
    z-index: 1000

.main-wrapper
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    gap: 20px
    width: auto
    box-sizing: border-box

    @media (max-width: 768px)
        width: 100%
        flex-direction: column
        gap: 15px

.summary-section
    width: 100%
    max-width: 40vw
    position: relative
    border-radius: 8px
    background-color: #fff
    border: 1px solid #ff5522
    padding: 20px
    gap: 16px
    box-sizing: border-box
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    text-align: left

    @media (max-width: 768px)
        max-width: 100vw

.summary-title
    width: auto
    position: relative
    font-family: 'Open Sans'
    text-align: left
    display: inline-block

.right
    position: relative
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    font-size: 16px
    font-family: 'Poppins'

.amount
    position: relative
    font-size: 16px
    font-family: 'Poppins'
    color: #005581
    font-weight: 700
    margin: 0 0

.divider
    height: 100%
    width: 1px
    border-right: 1px solid #e8e8e8

    @media (max-width: 768px)
        display: none

.payment-section
    margin: 0 0
    width: 100%
    text-align: center

    @media (max-width: 768px)
        padding: 0px

.message-section
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    gap: 20px

.message
    font-family: 'Poppins'
    max-width: 400px
    font-size: 16px
    font-weight: 500
    color: #0078d7
    text-align: center
    margin-top: 20px
    margin-bottom: 0
.toggle-switch
    display: flex
    justify-content: center
    align-items: center
    flex-direction: row
    gap: 0.5rem
    width: auto

    @media (max-width: 768px)
        width: 100%

.option-label
    font-size: 16px
    font-weight: 400
    font-family: 'Poppins'
    text-align: center
    color: #282828

.option-label-checked
    font-weight: 600
    color: #02273a
.profile-picture-wrapper
    width: 204px
    height: 204px
    position: relative
    border-radius: 50%
    overflow: hidden
    object-fit: cover
    cursor: pointer
    display: block
    margin: 0 auto

    &:hover
        .camera-overlay
            z-index: 1
            display: flex
            background-color: rgba(0, 0, 0, 0.7)


.profile-picture
    width: 100%
    height: 100%
    object-fit: cover
    position: relative
    overflow: hidden
    object-fit: cover
    display: block
    margin: 0 auto

.loader-overlay
    position: absolute
    width: 100%
    height: 100%
    top: 0
    left: 0
    background-color: rgba(0, 0, 0, 0.5)
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 8px
    color: #ffffff
    font-size: 16px
    font-weight: 700

    @media (max-width: 1024px)
        font-size: 16px

    @media (max-width: 768px)
        font-size: 16px

.camera-overlay
    position: absolute
    width: 100%
    height: 100%
    top: 0
    left: 0
    background-color: rgba(0, 0, 0, 0.5)
    display: none
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 8px
    color: #ffffff
    font-size: 16px
    font-weight: 700
    cursor: pointer

    @media (max-width: 1024px)
        font-size: 16px

    @media (max-width: 768px)
        font-size: 16px

.camera-icon
    width: 100%
    position: relative
    overflow: hidden
    font-size: 40px
    flex-shrink: 0
    color: #ffffff

.camera-text
    width: 100%
    position: relative
    font-size: 14px
    font-family: 'Open Sans'
    color: #ffffff
    text-align: center
    display: inline-block